import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CAR_LIST } from "@utils/static-data";
import CarCard from "./Partial/CarCard";

export default function CarCards() {
  return (
    <section id="car-cards">
      <Container>
        <Row>
          {CAR_LIST.map((val, _) => (
            <Col md={3} xs={6} key={val.title}>
              <CarCard {...val} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

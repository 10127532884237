import React from "react";
import { Button } from "react-bootstrap";
import { CarTypeProps } from "@utils/static-data";

export default function CarType(prop: CarTypeProps) {
  return (
    <Button variant="light" className="bg-white car-btn">
      {prop.text}
    </Button>
  );
}

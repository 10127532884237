import React from "react";
import { Card } from "react-bootstrap";
import { CarListProps } from "@utils/static-data";

export default function CarCard(prop: CarListProps) {
  return (
    <Card className="mb-4 border-0 bg-transparent" >
      <Card.Img
        alt="ekonomis"
        src={prop.img}
        style={{ zIndex: 0, maxHeight: 210, objectFit: "cover" }}
      />
      <Card.Body
        className="shadow mx-2 border py-1 d-flex justify-content-center align-items-center"
        style={{ marginTop: "-2rem", zIndex: 1, backgroundColor: "#fff" }}
      >
        <h4 className="text-center mt-2">{prop.title}</h4>
      </Card.Body>
    </Card>
  );
}

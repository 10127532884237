import { OUR_VIDEO } from "@utils/static-data";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function OurVideos() {
  return (
    <section id="our-videos">
      <Container>
        <h1>TONTON VIDEO KAMI LAINNYA</h1>
        <Row className="justify-content-between">
          {OUR_VIDEO.map(val => (
            <Col xs={12} md={3} key={val.title}>
              <iframe
                width="100%"
                src={val.embedLink}
                title={`YouTube: ${val.title}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <h4>{val.title}</h4>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

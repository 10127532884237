import { OUR_LOCATION } from "@utils/static-data";
import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

export default function OurLocations() {
  return (
    <section className="py-5" id="our-locations">
      <Container>
        <h1>LOKASI KAMI</h1>

        <Row className="mb-3">
          {OUR_LOCATION.map(location => (
            <Col xs={12} md={4} key={location.address}>
              <Card className="bg-transparent border-0">
                <Card.Header className="bg-transparent border-0 p-0">
                  <h2>{location.title}</h2>
                  <iframe
                    className="border-0"
                    src={location.embedLink}
                    width="100%"
                    height="350"
                    allowFullScreen={false}
                    loading="lazy"
                  />
                </Card.Header>
                <Card.Body className="px-2">
                  <p>{location.address}</p>

                  <Button
                    as="a"
                    href={location.btnLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    BUKA GMAPS
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <h2>CABANG LAINNYA</h2>
        <p>
          Sidoarjo Surabaya Jawa timur
          <br />
          DIY JATENG
        </p>
      </Container>
    </section>
  );
}

import React from "react";
import { Button } from "react-bootstrap";

export default function HeroHeader() {
  return (
    <section id="hero">
      <div className="hero-header">
        <div className="image">
          <img src="/images/home/hero-image.png" alt="HERO IMAGE" />
        </div>

        <div className="hero-paragraph container d-flex flex-column align-items-center justify-content-center">
          <h1>
            <span className="mobile-text-break">LEBIH DARI&nbsp;</span>
            <span className="mobile-text-break">
              <b>500++ MOBIL AMBULANCE&nbsp;</b>
            </span>
            <span className="mobile-text-break">TELAH KAMI KERJAKAN</span>
          </h1>
          <div className="paragraph-wrapper">
            <p>
              <span className="mobile-text-break">
                Kami telah beroperasi lebih dari 10 Tahun
              </span>
              <span className="mobile-text-break">
                dan juga telah melayani service Ambulance
              </span>
              <span className="mobile-text-break">
                secara Nasional dari Sabang hingga Marauke
              </span>
            </p>
            <Button
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/0811101505?text=Halo Pak Trisno, saya ingin tanya perihal mobil ambulance."
              variant="success"
              className="btn-hubungi"
            >
              HUBUNGI KAMI
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import Home from "@views/Home";
import Layout from "@components/Layout";

export default function Index() {
  return (
    <Layout>
      <Home />
    </Layout>
  );
}

import React from "react";
import CarCards from "./Sections/CarCards";
import CarTypes from "./Sections/CarTypes";
import HeroHeader from "./Sections/HeroHeader";
import OurAdvantages from "./Sections/OurAdvantages";
import OurLocations from "./Sections/OurLocations";
import OurVideos from "./Sections/OurVideos";

export default function Home() {
  return <div>
    <HeroHeader />
    <CarCards />
    <CarTypes />
    <OurAdvantages />
    <OurVideos />
    <OurLocations />
  </div>
}

import { CAR_TYPES } from "@utils/static-data";
import React from "react";
import { Col, Row } from "react-bootstrap";
import CarType from "./Partial/CarType";

export default function CarTypes() {
  return (
    <section id="car-types">
      <Row>
        <Col xs={12} md={6}>
          <div className="car-image">
            <img src="/images/home/car-type-image.png" alt="car-type" />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="car-content d-flex flex-column justify-content-center">
            <h1>JENIS MOBIL</h1>
            <Row style={{ maxWidth: 751 }}>
              {CAR_TYPES.map((type, _) => (
                <Col xs={6} key={type.text}>
                  <CarType {...type} />
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    </section>
  );
}

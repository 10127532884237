import { OUR_ADVANTAGES } from "@utils/static-data";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function OurAdvantages() {
  return (
    <section id="our-advantages">
      <Container>
        <h1>KEUNGGULAN KAMI</h1>
        <Row>
          {OUR_ADVANTAGES.map(val => (
            <Col xs={6} md={3} key={val.icon}>
              <div className="d-flex align-items-center">
                <img className="mr-3" src={val.icon} alt="icon" />
                <p className="mb-0" dangerouslySetInnerHTML={{ __html: val.textHtml }} />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
